import { UserService } from './../../../core/services/user/user.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../../core/services/util.service';
import { BreadcrumbService } from '../../../core/services/breadcrumb-service.service';
@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.less'],
})
export class UserComponent implements OnInit, OnDestroy {
    hideHybrid() {
        return !this.router.url.startsWith('/hybrid/account/');
    }

    constructor(
        public util: UtilService,
        public breadCrumb: BreadcrumbService,
        public router: Router,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.breadCrumb.pushPage('itemMenu.users', '/admin/users');
    }

    public canShowBreadCrumb() {
        return !(
            this.userService.object &&
            this.router.url == `/admin/users/${this.userService.object.userId}/sub-accounts`
        );
    }

    ngOnDestroy() {
        this.breadCrumb.popPage();
    }
}
