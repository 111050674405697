import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    /** Default API URL */
    public apiUrl: string = '';

    public isSafira: boolean = false;

    /** Public Safira IPs */
    public safiraIps: string[] = ['217.67.236.9', '195.134.175.56', '172.17.0.1'];

    /** Hestia IP prefix */
    public hestiaInternal: string = '10.10.10.';

    /** BMS version (pulled from API) */
    public version: string = '';

    /** key used for google services, key can be found in safira passwords */
    public googleAPIKey = '';

    /** Checkbox at login to store either in local or session storage */
    public rememberMe: boolean;

    /** Shows password value */
    public showPassword: boolean = false;

    public isHybrid = false;

    /** Icons used for specific input types */
    public typeIcons: object = {
        date: 'far fa-calendar',
        time: 'fal fa-clock',
        tel: 'fas fa-phone',
        email: 'fal fa-envelope',
        iban: 'fal fa-credit-card-front',
        popup: 'fal fa-info-circle',
    };

    public constructor(private storage: StorageService) {
        this.rememberMe = this.storage.usingLocalStorage();
        if (location.hostname.includes('bms-test2')) {
            this.apiUrl = 'https://bms-test2.safira-test.nl/api/v2.0/';
        } else if (location.hostname.includes('bms-test3')) {
            this.apiUrl = 'https://bms-test3.safira-test.nl/api/v2.0/';
        } else if (location.hostname.includes('bms-test')) {
            this.apiUrl = 'https://bms-test.safira-test.nl/api/v2.0/';
        } else if (location.hostname.includes('bms-accept')) {
            this.apiUrl = 'https://bms-accept.safira-test.nl/api/v2.0/';
        } else if (
            location.hostname.includes('bms-dev') ||
            location.hostname === 'localhost' ||
            location.hostname === '127.0.0.1'
        ) {
            this.apiUrl = 'https://bms-dev.safira-test.nl/api/v2.0/';
        } else {
            this.apiUrl = 'https://bms.safira-test.nl/api/v2.0/';
        }
    }
}
