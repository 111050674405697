import { Injectable } from '@angular/core';
import { AjaxService } from './ajax.service';
import { ToasterService } from './toaster.service';
import { UtilService } from './util.service';

const REQUIRED: string = ': is required.';
const MAXLENGTH: string = ': Cant be longer then ';
const EMAIL: string = ': is not a valid email.';
const DATE: string = ': Date not valid.';
const PASSWORDMINLENGTH: string = ': Password too short.';
const PASSWORDSTRENGTH: string = ': Password not strong enough.';
const TIME: string = ': Time not valid.';

const ERRORTEXT: string = 'error-text';
const VALIDTEXT: string = 'valid-text';

@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    public errorFound: boolean = false;
    public errors = {
        dateValidation: false,
        dateTimeValidation: false,
        emailValidation: false,
        numberValidation: false,
        passwordValidation: false,
        textOnlyValidation: false,
        timeValidation: false,
        zipcodeValidation: false,
        websiteValidation: false,
        decimalValidation: false,
        telephoneValidation: false,
        usernameValidation: false,
        stringValidation: false,
        requiredValidation: false,
        ibanValidation: false,
    };
    private elementForValidation = ['sinput', 'sselect'];
    private passwordRanks = ['Too Short', 'Weak', 'Medium', 'Strong', 'Very Strong'];

    public constructor(
        private http: AjaxService,
        private toaster: ToasterService,
        private util: UtilService
    ) {}

    public checkForErrors(array: String[], toaster: Boolean = true) {
        if ((<any>array).length > 0) {
            for (let i = 0; i < (<any>array).length; i++) {
                let _elements = this.util.getElements('.' + array[i]);
                if (_elements.length > 1) {
                    for (let j = 0; j < _elements.length; j++) {
                        if (this.util.findParentWithClass(_elements[j], 'form-group')) {
                            this.util.addClass(
                                this.util.findParentWithClass(_elements[j], 'form-group'),
                                'app-input-error'
                            );
                        }
                    }
                } else {
                    if (this.util.findParentWithClass(_elements[0], 'form-group')) {
                        this.util.addClass(
                            this.util.findParentWithClass(_elements[0], 'form-group'),
                            'app-input-error'
                        );
                    } else {
                        this.util.addClass(_elements[0], 'app-error');
                    }
                }
            }

            if (toaster) {
                this.toaster.error('Niet alle velden zijn ingevuld');
            }
        }
    }

    /**
     * checkErrors
     *
     * check if there is an error
     *
     * @author    Bart van Maarschalkerweerd <bart@safira.nl>
     */
    public checkErrors(): boolean {
        if (
            this.errors.dateValidation ||
            this.errors.dateTimeValidation ||
            this.errors.emailValidation ||
            this.errors.numberValidation ||
            this.errors.passwordValidation ||
            this.errors.textOnlyValidation ||
            this.errors.timeValidation ||
            this.errors.zipcodeValidation ||
            this.errors.websiteValidation ||
            this.errors.decimalValidation ||
            this.errors.telephoneValidation ||
            this.errors.usernameValidation ||
            this.errors.stringValidation ||
            this.errors.requiredValidation ||
            this.errors.ibanValidation
        ) {
            this.errorFound = true;
            return true;
        } else {
            this.errorFound = false;
            return false;
        }
    }

    /**
     * resetErrors
     *
     * set all error variables to false
     *
     * @author    Bart van Maarschalkerweerd <bart@safira.nl>
     */
    public resetErrors(): void {
        (this.errors.dateValidation = false),
            (this.errors.dateTimeValidation = false),
            (this.errors.emailValidation = false),
            (this.errors.numberValidation = false),
            (this.errors.passwordValidation = false),
            (this.errors.textOnlyValidation = false),
            (this.errors.timeValidation = false),
            (this.errors.zipcodeValidation = false),
            (this.errors.websiteValidation = false),
            (this.errors.decimalValidation = false),
            (this.errors.telephoneValidation = false),
            (this.errors.usernameValidation = false),
            (this.errors.stringValidation = false),
            (this.errors.requiredValidation = false),
            (this.errors.ibanValidation = false);

        this.errorFound = false;
    }

    /**
     * validationPossible
     *
     * check if theres an element which needs to be validated
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public validationPossible(nativeElement: HTMLElement): boolean {
        const form: Element = nativeElement.children[0];
        const elements: HTMLCollection = form.children;

        return [].some.call(elements, (element: HTMLElement) =>
            this.elementValidationPossible(element.localName)
        );
    }

    /**
     * validationPossible
     *
     * check if element.localName is in the elementForValidation array
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public elementValidationPossible(localName: string): boolean {
        return this.elementForValidation.indexOf(localName) > -1;
    }

    /**
     * sinputValidate
     *
     * validate the input of sInput
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    private sinputValidate(element: HTMLElement): [boolean, string] {
        const input: HTMLInputElement = <HTMLInputElement>element.querySelector('input');
        const label: HTMLElement = <HTMLElement>element.querySelector('label');

        // requiredValidation
        if (element.attributes['required']) {
            if (!this.requiredValidation(input)) {
                return [false, label.textContent + REQUIRED];
            }
        }

        // maxLengthValidation
        if (element.attributes['maxLength']) {
            if (!this.maxLengthValidation(element, input)) {
                return [false, label.textContent + MAXLENGTH + element.attributes['maxLength'].nodeValue];
            }
        }

        // emailValidation
        if (input.type === 'email') {
            if (!this.emailValidation(input)) {
                return [false, label.textContent + EMAIL];
            }
        }

        // dateValidation
        if (input.type === 'date') {
            if (!this.dateValidation(input)) {
                return [false, label.textContent + DATE];
            }
        }

        // passwordValidation
        if (input.type === 'password') {
            const passwordValidationReturnType: [boolean, string] = this.passwordValidation(
                input,
                label,
                'Strong'
            );
            if (!passwordValidationReturnType[0]) {
                return passwordValidationReturnType;
            }
        }

        // timeValidation
        if (input.type === 'time') {
            if (!this.timeValidation(input)) {
                return [false, label.textContent + TIME];
            }
        }

        // for IE
        if (input.attributes['data-fieldtype'].nodeValue === 'time') {
            if (!this.timeValidation(input)) {
                return [false, label.textContent + TIME];
            }
        }

        if (input.attributes['data-fieldtype'].nodeValue === 'date') {
            if (!this.dateValidation(input)) {
                return [false, label.textContent + DATE];
            }
        }

        return [true, ''];
    }

    /**
     * sselectValidate
     *
     * validate the input of sSelect
     *
     * @author        Wilfred van Eck <wilfred@safira.nl>
     * @lastEdit      11-07-2018 10:40
     * @lastEditBy    Wilfred van Eck <wilfred@safira.nl>
     */
    public sselectValidate(element: HTMLElement): [boolean, string] {
        let select: HTMLSelectElement = <HTMLSelectElement>element.children[0].children[1].children[0];
        let label: Element = element.children[0].children[0];

        if (select.value === '') {
            return [false, label.textContent + ': Select an option.'];
        }

        return [true, ''];
    }

    /**
     * checkPasswordStrength
     *
     * checks the strength of the password
     * The strength factors are the following
     * lowercase +1
     * UPPERCASE +1
     * number +1
     * special char +1
     * +1 for each 2 characters more than the minimum (normally the minimum is 8)
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public checkPasswordStrength(password: string): string {
        const upper: RegExp = /[A-Z]/;
        const lower: RegExp = /[a-z]/;
        const number: RegExp = /[0-9]/;
        const special: RegExp = /[^A-Za-z0-9]/;
        const minLength: number = 8;
        let score: number = 0;

        if (password.length < minLength) {
            return 'Too Short';
        }

        // Increment the score for each of these conditions
        if (password.match(upper)) {
            score++;
        }
        if (password.match(lower)) {
            score++;
        }
        if (password.match(number)) {
            score++;
        }
        if (password.match(special)) {
            score++;
        }

        // Penalize if there aren't at least three char types
        if (score < 3) {
            score--;
        }

        if (password.length > minLength) {
            // Increment the score for every 2 chars longer than the minimum
            score += Math.floor((password.length - minLength) / 2);
        }

        if (score < 3) {
            return 'Weak';
        }
        if (score < 4) {
            return 'Medium';
        }
        if (score < 6) {
            return 'Strong';
        }

        return 'Very Strong';
    }

    /**
     * passwordValidation
     *
     * checks if the password is valid
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public passwordValidation(
        input: HTMLInputElement,
        label: HTMLElement,
        wantedStrength: string
    ): [boolean, string] {
        if (input.value === '') {
            return [true, ''];
        }

        const passwordStrength: string = this.checkPasswordStrength(input.value);

        if (passwordStrength === 'Too Short') {
            return [false, label.textContent + PASSWORDMINLENGTH];
        }

        if (
            this.passwordRanks.findIndex((rank) => rank === passwordStrength) <
            this.passwordRanks.findIndex((rank) => rank === wantedStrength)
        ) {
            return [false, label.textContent + PASSWORDSTRENGTH];
        }

        return [true, ''];
    }

    /**
     * requiredValidation
     *
     * checks if the required input is not empty
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public requiredValidation(input: HTMLInputElement): boolean {
        return input.value !== '';
    }

    /**
     * maxLengthValidation
     *
     * checks input is not greater then the maxLength
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    private maxLengthValidation(element: HTMLElement, input: HTMLInputElement): boolean {
        return input.value.length <= element.attributes['maxLength'].nodeValue;
    }

    /**
     * emailValidation
     *
     * checks if the input is an email
     *
     * @author        Wilfred van Eck <wilfred@safira.nl>
     * @lastEdit      11-07-2018 12:10
     * @lastEditBy    Wilfred van Eck <wilfred@safira.nl>
     */
    public emailValidation(input: HTMLInputElement): boolean {
        if (input.value === '') {
            return true;
        }

        return /^([\w-]+(?:[.+][\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(
            input.value
        );
    }

    /**
     * dateValidation
     *
     * checks if the input is a date
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public dateValidation(input: HTMLInputElement): boolean {
        if (input.value === '') {
            return true;
        }

        return /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/i.test(input.value);
    }

    /**
     * timeValidation
     *
     * checks if the input is a time
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public timeValidation(input: HTMLInputElement): boolean {
        if (input.value === '') {
            return true;
        }

        return /^([01]?\d|2[0-3]):([0-5]\d)$/i.test(input.value);
    }

    /**
     * getRankIndexByStringName
     *
     * get the index of the strength rank
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    public getRankIndexByStringName(strengthRank: string): number {
        return this.passwordRanks.findIndex((rank) => rank === strengthRank);
    }

    /**
     * getIbanValidaiton
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      iban    string
     * @returns    Promise<any>
     */
    public getIbanValidation(iban: string) {
        return new Promise((resolve, reject) => {
            this.http.post('validator/validateIBAN', { iban: iban }).then(
                (result: object) => {
                    resolve(result);
                },
                (error: any) => {
                    reject(error);
                }
            );
        });
    }
}
