import { ImportComponent } from './user/import/import.component';
import { RouterModule, Routes } from '@angular/router';
import { LanguageComponent } from './language/language.component';
import { AdminComponent } from './admin.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';
import { ResetOpcacheComponent } from '../../core/components/reset-opcache/reset-opcache.component';
import { TranslationComponent } from './translation/translation.component';
import { UserFormComponent } from './user/user-form/user-form.component';
import { RightsComponent } from './user/user-form/rights/rights.component';
import { AddressComponent } from './user/user-form/address/address.component';
import { GeneralComponent } from './user/user-form/general/general.component';
import { TwoFactorAuthenticationComponent } from './two-factor-authentication/two-factor-authentication.component';
import { UserComponent } from './user/user.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { ModuleComponent } from './module/module.component';

export const adminRoutes: Routes = [
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuardService],
        data: {
            admin: true,
        },
        children: [
            {
                path: 'users',
                component: UserComponent,
                children: [
                    {
                        path: '',
                        component: UserListComponent,
                        pathMatch: 'full',
                    },
                    {
                        path: 'new',
                        component: UserFormComponent,
                        children: [
                            {
                                path: '',
                                component: GeneralComponent,
                                pathMatch: 'full',
                            },
                        ],
                    },
                    {
                        path: ':userId/sub-accounts',
                        component: UserListComponent,
                    },
                    {
                        path: ':userId/sub-accounts/new',
                        component: UserFormComponent,
                        children: [
                            {
                                path: '',
                                component: GeneralComponent,
                                pathMatch: 'full',
                            },
                        ],
                    },
                    {
                        path: ':userId/sub-accounts/:subId',
                        component: UserFormComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'general',
                                pathMatch: 'full',
                            },
                            {
                                path: 'general',
                                component: GeneralComponent,
                            },
                            {
                                path: 'address',
                                component: AddressComponent,
                            },
                            {
                                path: 'rights',
                                component: RightsComponent,
                            },
                        ],
                    },
                    {
                        path: ':userId',
                        component: UserFormComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'general',
                                pathMatch: 'full',
                            },
                            {
                                path: 'general',
                                component: GeneralComponent,
                            },
                            {
                                path: 'address',
                                component: AddressComponent,
                            },
                            {
                                path: 'rights',
                                component: RightsComponent,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'languages',
                component: LanguageComponent,
            },
            {
                path: 'translations',
                component: TranslationComponent,
            },
            {
                path: 'modules',
                component: ModuleComponent,
            },
            {
                path: 'two-factor-authentication',
                component: TwoFactorAuthenticationComponent,
            },
            {
                path: 'reset-cache',
                component: ResetOpcacheComponent,
            },
            {
                path: 'import',
                component: ImportComponent,
            },
        ],
    },
];
export const adminRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(adminRoutes);
