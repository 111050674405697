import { CrudModel } from '../crud/crud.model';

export class UserGroupModel extends CrudModel<UserGroupModel> {
    groupId: number;
    name: string;
    description: string;
    active: number;
    adminGroup: boolean;

    protected readonly excluded: string[] = ['adminGroup', 'deleted'];
}
