type ErrorItem = {
	errorLanguage: string,
    errorType: string,
    message: string,
    sender: string
	stacktrace: string,
	sendAt: string, 
	file: string,
	line: number,
}	

export class DataStorageService {
    private errorArray : ErrorItem[] = [];

    storeError(error: ErrorItem){
        if(error){
            this.errorArray.push(error);
        }
    }

    getErrors(){
        if(this.errorArray.length > 0){
            return (this.errorArray);
        }
        return null;
    }

    clearErrors() : void {
        this.errorArray = [];
    }
}