import * as stackTraceParser from 'stacktrace-parser';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from '../toaster.service';
import { DataStorageService } from '../datastorage/dataStorage.service';
import { environment } from '../../../../environments/environment';

type ErrorItem = {
	errorLanguage: string,
	errorType: string,
	message: string,
	sender: string
	stacktrace: string,
	sendAt: string, 
	file: string,
	line: number,
}	

@Injectable({
	providedIn: 'root'
})

export class ErrorMonitoringService  implements ErrorHandler{
	public constructor(
		private toaster: ToasterService,
		private zone: NgZone,
		private dataStorage: DataStorageService,
	) {
	}

	public handleError(error: Error | HttpErrorResponse): void {
		if(error){
			if(!(error instanceof HttpErrorResponse)){
				const stack = stackTraceParser.parse(error.stack);
				let item : ErrorItem = {
					errorLanguage: environment.errorLanguage,
					errorType: error.name,
					sender: environment.sender,
					message: error.message,
					stacktrace: error.stack,
					sendAt: (new Date()).toISOString(), 
					file: stack[0].file,
					line: stack[0].lineNumber,
				};
				this.dataStorage.storeError(item)
				console.log(item);
			}
		}
	}

	public showToasterError(errorMessage : string){
		this.zone.run(() =>
		this.toaster.error(errorMessage)
		);
	}

	public handleException(error: any, returnerr: boolean): any {
		if (!returnerr) {
			return 'Het lijkt er op dat er iets fout is gegaan.';
		} else if (!environment.production && returnerr) {
			try {
				const resp = JSON.parse(error['_body']);
				return resp.message;
			} catch (e) {
				console.log("Probably not json: " + e);
				const resp = JSON.stringify(error);
				return resp;
			}
		}
	}

}
