import { HybridExampleComponent } from './hybrid-example/hybrid-example.component';
import { CmsModule } from './../../core/components/cms/cms.module';
import { hybridRouting } from './hybrid.routes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticateModule } from '../authenticate/authenticate.module';
import { UserModule } from '../admin/user/user.module';

@NgModule({
    declarations: [HybridExampleComponent],
    imports: [hybridRouting, CommonModule, CmsModule, AuthenticateModule, UserModule],
})
export class HybridModule {}
