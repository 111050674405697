import { ConfigService } from 'src/app/core/services/config.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToasterService } from '../toaster.service';
import { CrudServiceModel } from '../../models/crud/crud-service.model';
import { UserModel } from '../../models/user/user.model';
import { AjaxService } from '../ajax.service';
import { UserAddressModel } from '../../models/user/user-address.model';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class AccountService extends CrudServiceModel<UserModel> {
    public accountUpdated: Subject<void> = new Subject<void>();

    protected module = 'user/account';
    protected create = UserModel;

    constructor(
        protected ajax: AjaxService,
        private toaster: ToasterService,
        private userService: UserService,
        private config: ConfigService
    ) {
        super();
    }

    protected validation(): boolean {
        return this.userService.validator(this.object);
    }

    async save(object: UserModel[] | UserModel = this.object): Promise<UserModel | UserModel[]> {
        try {
            return super.save(object);
        } catch (error) {
            this.toaster.error(error.message);
        }
    }

    /**
     * Gets information about the currently logged-in user
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public async get(): Promise<UserModel> {
        await super.get();
        this.config.version = this.object['version'];
        delete this.object['version'];
        this.accountUpdated.next();
        return this.object;
    }

    /**
     * Gets information about the currently logged-in user adresses
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    public async includeAddresses(): Promise<UserModel> {
        const addresses = await this.getEndpoint('getAddresses');
        return (this.object.addresses = addresses.map((address) => {
            return new UserAddressModel(address);
        }));
    }
}
