import { LocationService } from '../../../../../core/services/location/location.service';
import { Component, OnInit } from '@angular/core';
import { ToasterService } from '../../../../../core/services/toaster.service';

@Component({
    selector: 'geo-info',
    templateUrl: './geo-info.component.html',
    styleUrls: ['./geo-info.component.less'],
})
export class GeoInfoComponent implements OnInit {
    public radius: number = 5;
    public usersWithinRange: any[];
    public usersFound = false;

    constructor(private locationService: LocationService, private toaster: ToasterService) {}

    ngOnInit(): void {}

    /**
     * get users within range based on current position
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public getUsers(): void {
        if (this.radius > 0) {
            this.locationService.getCurrentLocation(async (pos) => {
                this.usersWithinRange = await this.locationService.getUsersWithinRange(
                    this.radius,
                    pos.coords.longitude,
                    pos.coords.latitude
                );
                if (this.usersWithinRange !== null) {
                    this.usersFound = true;
                } else {
                    this.toaster.error('Er zijn geen gebruikers gevonden in uw radius');
                    this.usersFound = false;
                }
            });
        } else {
            this.toaster.error('Vul een radius in hoger dan 0');
        }
    }
}
