import { Directive, HostListener, OnInit } from '@angular/core';
import { UtilService } from '../../services/util.service';

const ATTRIBUTE_NAME: string = 'tab-number';
const ACTIVE_TAB: string = 'active-tab';
const ACTIVE_CONTENT: string = 'active-content';
const CONTENT_WRAPPER_CLASS: string = 'tab-wrapper';
const CONTENT_CLASS: string = 'content-tab';
const TAB_ITEM_CLASS: string = 'tab-item';
const WRAPPER_ATTRIBUTE: string = 'wrapper-number';
const LIST_ITEM_ATTRIBUTE: string = 'tabNumber';
const LIST_CLASS: string = 'tabs-list';

@Directive({
    selector: '[data-tab]',
})
export class TabDirective implements OnInit {
    private _tabsList: Array<HTMLElement> = [];
    private _scrollButtons: Array<HTMLElement> = [];

    /**
     * onClick
     *
     * Check on which tab the user clicked and toggle the content accordingly.
     *
     * @author    Jurre Kon <jurre@safira.nl>
     */
    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent): void {
        let $clickedElement: HTMLElement = <HTMLElement>event.target;
        if (
            ($clickedElement.classList.contains(TAB_ITEM_CLASS) &&
                !$clickedElement.classList.contains('active-tab')) ||
            this.util.findParentWithClass($clickedElement, TAB_ITEM_CLASS)
        ) {
            if (this.util.findParentWithClass($clickedElement, TAB_ITEM_CLASS)) {
                $clickedElement = this.util.findParentWithClass($clickedElement, TAB_ITEM_CLASS);
            }
            const $parentUL: HTMLElement = this.util.findParentWithClass($clickedElement, LIST_CLASS);
            const wrapperNumber: string = $parentUL.getAttribute(WRAPPER_ATTRIBUTE);
            const childListArray: Array<HTMLElement> = this.util.findChildrenWithClass(
                $parentUL,
                TAB_ITEM_CLASS
            );

            for (const element of childListArray) {
                this.util.removeClass(element, ACTIVE_TAB);
            }

            this.util.addClass($clickedElement, ACTIVE_TAB);

            const activeItemnumber: string = $clickedElement.getAttribute(ATTRIBUTE_NAME);
            const tabContentWrapper: Array<HTMLElement> = this.util.getElements(`.${CONTENT_WRAPPER_CLASS}`);

            for (const wrapper of tabContentWrapper) {
                if (wrapper.getAttribute(WRAPPER_ATTRIBUTE) === wrapperNumber) {
                    const currentActiveTab: HTMLElement = this.util.getChild(wrapper, ACTIVE_CONTENT);

                    this.util.removeClass(currentActiveTab, ACTIVE_CONTENT);

                    const childContentArray: Array<HTMLElement> = this.util.getElements(`.${CONTENT_CLASS}`);

                    for (const child of childContentArray) {
                        if (child.getAttribute(ATTRIBUTE_NAME) === activeItemnumber)
                            this.util.addClass(child, ACTIVE_CONTENT);
                    }
                }
            }
        }

        for (let i: number = 0; i < this._tabsList.length; i++) {
            if (this._tabsList[i].offsetWidth < this._tabsList[i].scrollWidth) {
                for (let x: number = 0; x < this._scrollButtons.length; x++) {
                    if (parseInt(this._scrollButtons[x].getAttribute(LIST_ITEM_ATTRIBUTE), 10) == i) {
                        this._scrollButtons[x].style.display = 'block';
                    }
                }
            } else if (this._tabsList[i].offsetWidth >= this._tabsList[i].scrollWidth) {
                for (let x: number = 0; x < this._scrollButtons.length; x++) {
                    if (parseInt(this._scrollButtons[x].getAttribute(LIST_ITEM_ATTRIBUTE), 10) == i) {
                        this._scrollButtons[x].style.display = 'none';
                    }
                }
            }
        }
    }

    public constructor(public util: UtilService) {}

    public ngOnInit(): void {
        this._tabsList = this.util.getElements('.tabs-list');
        this._scrollButtons = this.util.getElements('.scroll');
    }
}
