import { AjaxService } from '../../services/ajax.service';
import { CrudModel } from './crud.model';
import { CrudException } from './crud-exception.model';
import { HttpStatusCode } from '../http-status-code';
import { ApiModel } from './api.model';

export abstract class CrudServiceModel<Model extends CrudModel<Model>> {
    public object: Model;

    protected abstract ajax: AjaxService;
    protected abstract readonly module: string;
    protected abstract readonly create: {
        new (save: (object?: Model[] | Model) => Promise<Model[] | Model>, input?: Object): Model;
    };

    /**
     * Used to customize your front-end validation.
     * Called before saving to the database or calling to validate manually.
     *
     * @note      You have to implement this method.
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    protected abstract validation(object: Model): boolean;

    /**
     * A general method to setup an easy endpoint in the front-end.
     * May only be used by children of this class to prevent unset endpoints.
     *
     * Endpoint: <endpoint>
     *
     * Sends to API:
     * <param>: any
     *
     * Expects return:
     * object: any
     * error: { code: number, message: string } | undefined
     *
     * @throws    CrudException
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    protected async getEndpoint(endpoint: string, param: any = [], loadingTarget?: string): Promise<any> {
        const response = await this.ajax.post(`${this.module}/${endpoint}`, param, loadingTarget);
        if (response.error !== undefined && response.error.code !== 0) {
            throw new CrudException(response.error.code, response.error.message);
        }
        return response.object;
    }

    /**
     * Get all objects of given Model
     * Doesn't save all this information in the service.
     *
     * Endpoint: <endpoint>
     *
     * Sends to API:
     * <param>: any
     *
     * Expects return:
     * objects: Model[]
     * error: { code: number, message: string } | undefined
     *
     * @throws    CrudException
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    protected async getModelEndpoint(
        endpoint: string,
        param?: any,
        loadingTarget?: string
    ): Promise<Model[]> {
        const response = await this.ajax.post(`${this.module}/${endpoint}`, param, loadingTarget);
        if (response.error !== undefined && response.error.code !== 0) {
            throw new CrudException(response.error.code, response.error.message);
        }
        return response.objects.map((model) => {
            return new this.create(this.save.bind(this), model);
        });
    }

    /**
     * Get all objects of given Model
     * Doesn't save all this information in the service.
     *
     * Endpoint: getAll
     *
     * Sends to API:
     * <param>: any
     *
     * Expects return:
     * objects: Model[]
     * error: { code: number, message: string } | undefined
     *
     * @throws    CrudException
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    async getAll(param?: any, loadingTarget?: string): Promise<Model[]> {
        const response = await this.ajax.post(`${this.module}/getAll`, param, loadingTarget);
        if (response.error !== undefined && response.error.code !== 0) {
            throw new CrudException(response.error.code, response.error.message);
        }
        return response.objects.map((model) => {
            return new this.create(this.save.bind(this), model);
        });
    }

    /**
     * Get object of given Model by param
     * Saves the object in the service for later use
     *
     * Endpoint: get
     *
     * Sends to API:
     * <param>: any
     *
     * Expects return:
     * object: Model
     * error: { code: number, message: string } | undefined
     *
     * @throws    CrudException
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    async get(param?: any, loadingTarget?: string): Promise<Model> {
        const response = await this.ajax.post(`${this.module}/get`, param, loadingTarget);
        if (response.error !== undefined && response.error.code !== 0) {
            throw new CrudException(response.error.code, response.error.message);
        }
        return (this.object = new this.create(this.save.bind(this), response.object));
    }

    /**
     * Delete the given object
     *
     * Doesn't use the object as default.
     * Because this can lead to accidental deletion of the entire list of objects
     *
     * Endpoint: delete
     *
     * Sends to API:
     * objects: Model[]
     *
     * Expects return:
     * error: { code: number, message: string } | undefined
     *
     * @throws    CrudException
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    async delete(object: Model | Model[]): Promise<void> {
        const objects = Array.isArray(object) ? object : [object];
        const response = await this.ajax.post(`${this.module}/delete`, { objects });
        if (response.error !== undefined && response.error.code !== 0) {
            throw new CrudException(response.error.code, response.error.message);
        }
    }

    /**
     * Saves the given object(s) or saves the services objects
     * Uses the service object as a default
     *
     * Endpoint: createOrUpdate
     *
     * Sends to API:
     * objects: Model[]
     * <param>: any
     *
     * Expects return:
     * object: Model | Model[]
     * error: { code: number, message: string } | undefined
     *
     * @throws    CrudException
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    async save(object: Model | Model[] = this.object, param?: any): Promise<Model | Model[]> {
        const objects = Array.isArray(object) ? object : [object];
        await this.validate(objects);
        const response = await this.ajax.post(`${this.module}/createOrUpdate`, {
            objects: objects.map((object) => {
                return object.serialize();
            }),
            param,
        });
        if (response.error !== undefined && response.error.code !== 0) {
            throw new CrudException(response.error.code, response.error.message);
        }
        if (Array.isArray(response.object)) {
            return response.object.map((model) => {
                return new this.create(this.save.bind(this), model);
            });
        }
        return (this.object = new this.create(this.save.bind(this), response.object));
    }

    /**
     * Validates the object(s) given or the services object
     *
     * @throws    CrudException
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    validate(object: Model | Model[] = this.object): void {
        const objects = Array.isArray(object) ? object : [object];
        for (let object of objects) {
            if (!this.validation(object)) {
                throw new CrudException(HttpStatusCode.BAD_REQUEST, 'validationError.Unknown');
            }
        }
    }

    /**
     * Checks if the given objects were changed
     *
     * @author    Lars Meeuwsen <lars@safira.nl>
     */
    changed(objects: ApiModel<any>[] = Array.isArray(this.object) ? this.object : [this.object]): boolean {
        return objects.some((object) => {
            return object !== undefined && object !== null && object.changed;
        });
    }
}
