import { Injectable } from "@angular/core"
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http"
import { Observable, from, of, throwError } from "rxjs"
import { DataStorageService } from "../services/datastorage/dataStorage.service"
import { AjaxService } from "../services/ajax.service"

const ERROR_MONITORING_URL = "errors/reportErrors";
const ACCEPT_HTTP = 202;

@Injectable()
export class ErrorMonitoringInterceptor implements HttpInterceptor {
    
    constructor(private data : DataStorageService, private ajax : AjaxService){}
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let result : Observable<HttpEvent<any>> = next.handle(req);
        let jsonErrors = this.data.getErrors();
        
        if(req.method === "POST" && req.url.includes(ERROR_MONITORING_URL)){
            return result;
        }

        if(jsonErrors){
            const sendData = {"jsonErrors": JSON.stringify(jsonErrors)};
            
            let errorRequest = this.ajax.postObservable(ERROR_MONITORING_URL, sendData);
            
            errorRequest.subscribe(
                (response: any) => {
                    if(response.status === ACCEPT_HTTP || response.status === 200){
                        this.data.clearErrors();
                    }
                },
                (error : any) => {
                    
                }
            )
        }

        return result;
    }

}
