import { Component } from '@angular/core';
import { UtilService } from '../../services/util.service';

@Component({
    selector: 'ios-notification',
    templateUrl: './ios-notification.component.html',
    styleUrls: ['./ios-notification.component.less'],
})
export class IosNotificationComponent {
    public readonly IS_CHROME: boolean = navigator.userAgent.match('/CriOS/i') != null;
    public readonly IS_IPHONE: boolean =
        navigator.userAgent.match(/iPhone/i) != null || navigator.userAgent.match(/iPod/i) != null;
    public identifier: string;

    public constructor(private util: UtilService) {}

    public ngAfterContentInit(): void {
        if (this.IS_IPHONE && !this.isInStandaloneMode()) {
            if (this.IS_IPHONE) {
                this.identifier = 'iPhone';
            }
            const $iosNotification: HTMLElement = this.util.getElement('#ios-notification');
            $iosNotification.classList.remove('hide');
        }
    }

    private isInStandaloneMode(): boolean {
        return 'standalone' in window.navigator && (<any>window.navigator).standalone;
    }
}
