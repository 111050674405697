import { ConfigService } from 'src/app/core/services/config.service';
import { UtilService } from '../util.service';
import { GoogleMapsComponent } from '../../components/googlemaps/googlemaps.component';
import { HttpClient } from '@angular/common/http';
import { abstractLocation, Address, Coordinates } from './abstractLocation';
import { ComponentRef, Type } from '@angular/core';
import { map } from 'rxjs/operators';
import { MapMarker } from './MapMarker.model';

export class googleMapsStrategy implements abstractLocation {
    public component: Type<any>;
    public key: string = 'google';
    public componentRef: ComponentRef<GoogleMapsComponent>;

    public mapPointers: Array<MapMarker> = [
        // {longitude: 5.30296, latitude: 51.87569, title: 'Safira', redirectUrl: 'https://safira.nl', img: '../assets/gras.jpg', subtitle: 'Geldermalsen', description: 'Omschrijving'} ,
        {
            longitude: 5.29761,
            latitude: 51.87658,
            title: 'Tennisveld',
            redirectUrl: 'blocks/geolocation/geo-info',
            img: 'assets/gras.jpg',
            subtitle: 'Geldermalsen',
            description: 'Omschrijving',
        },
        {
            longitude: 5.30562,
            latitude: 51.87579,
            title: 'Rotonde',
            redirectUrl: '',
            img: 'assets/gras.jpg',
            subtitle: 'Geldermalsen',
            description: 'Omschrijving',
        },
        {
            longitude: 5.30232,
            latitude: 51.8752,
            title: 'Bouman',
            redirectUrl: '',
            img: 'assets/gras.jpg',
            subtitle: 'Geldermalsen',
            description: 'Omschrijving',
        },
        {
            longitude: 5.29858,
            latitude: 51.87539,
            title: 'Weg',
            redirectUrl: '',
            img: 'assets/gras.jpg',
            subtitle: 'Geldermalsen',
            description: 'Omschrijving',
        },
    ];

    constructor(public http: HttpClient, private util: UtilService, private configService: ConfigService) {
        // link component to strategy;
        this.component = GoogleMapsComponent;
    }

    geocode(address: Address): Promise<Coordinates> {
        const url: URL = new URL('https://maps.googleapis.com/maps/api/geocode/json?address=');
        const urlEnd: string = ',NL,CA&key=' + this.configService.googleAPIKey;

        const searchAddress: string = (
            (!this.util.empty(address.houseNumber) ? address.houseNumber.toString(10).trim() : '') +
            (!this.util.empty(address.houseNumberSuffix) ? address.houseNumberSuffix.trim() : '') +
            (!this.util.empty(address.address) ? ',' + address.address.trim() : '') +
            (!this.util.empty(address.city) ? ',' + address.city.trim() : '') +
            (!this.util.empty(address.zipcode) ? ',' + address.zipcode.trim() : '') +
            urlEnd
        ).trim();

        return this.http
            .get(url.toString() + searchAddress)
            .pipe(
                map((value: JSON): Coordinates => {
                    //street address check to make sure the marker doesnt get placed even though the address cant be found
                    //it would return the coordinates of the postal code otherwise on google maps
                    if (value['status'] === 'OK' && value['results'][0]['types'][0] === 'street_address') {
                        return {
                            lon: value['results'][0]['geometry']['location']['lng'],
                            lat: value['results'][0]['geometry']['location']['lat'],
                        };
                    } else {
                        return null;
                    }
                })
            )
            .toPromise();
    }

    public setComponentReference(componentRef: ComponentRef<any>) {
        if (componentRef != null && componentRef.instance instanceof GoogleMapsComponent) {
            this.componentRef = componentRef;
        }
    }

    public getMarkers(): MapMarker[] {
        return this.componentRef.instance.markers;
    }

    // add information marker to google maps component
    public addMarker(marker: MapMarker): void {
        this.componentRef.instance.markers.push(marker);
        this.componentRef.instance.addMarkers([marker]);
    }

    public removeMarker(marker: MapMarker): void {
        this.componentRef.instance.removeMarker(marker);
    }

    public setViewPosition(coordinates: Coordinates): void {
        this.componentRef.instance.setPosition(coordinates);
    }

    public loadComponentData() {
        this.componentRef.instance.latitude = 51.8755494;
        this.componentRef.instance.longitude = 5.3027;
        this.componentRef.instance.zoomLevel = '15';
        this.componentRef.instance.mapType = 'roadmap';
        this.componentRef.instance.markers = this.mapPointers;
        this.componentRef.instance.draggable = true;
        // height of map in pixels;
        this.componentRef.instance.mapHeight = 710;
    }

    setMapHeight(height: number): void {
        if (this.componentRef) {
            this.componentRef.instance.mapHeight = height;
        }
    }
}
