RedactorX.lang['nl'] = {
    accessibility: {
        'help-label': 'Rijketekstbewerker',
    },
    placeholders: {
        figcaption: 'Typ bijschrift (optioneel)',
    },
    popup: {
        'back': 'Terug',
        'link': 'Koppeling',
        'add': 'Voeg Toe',
        'image': 'Afbeelding',
        'add-image': 'Voeg Afbeelding toe',
    },
    shortcuts: {
        'meta-a': 'Selecteer alles',
        'meta-z': 'Ongedaan maken',
        'meta-shift-z': 'Opnieuw',
        'meta-shift-m': 'Verwijder in-regel opmaak',
        'meta-b': 'Vet',
        'meta-i': 'Cursief',
        'meta-u': 'Onderstreept',
        'meta-h': 'Superscript',
        'meta-l': 'Subscript',
        'meta-k': 'Koppeling',
        'meta-alt-0': 'Normale tekst',
        'meta-alt-1': 'Koptekst 1',
        'meta-alt-2': 'Koptekst 2',
        'meta-alt-3': 'Koptekst 3',
        'meta-alt-4': 'Koptekst 4',
        'meta-alt-5': 'Koptekst 5',
        'meta-alt-6': 'Koptekst 6',
        'meta-shift-7': 'Geordende lijst',
        'meta-shift-8': 'Oneordende lijst',
        'meta-indent': 'Inspringen',
        'meta-outdent': 'Uitspringen',
        'meta-shift-backspace': 'Verwijderen',
        'meta-shift-o': 'Toevoegen',
        'meta-shift-d': 'Dupliceren',
        'meta-shift-up': 'Verplaats naar boven',
        'meta-shift-down': 'Verplaats naar beneden',
    },
    buttons: {
        'add': 'Voeg toe',
        'html': 'HTML',
        'format': 'Opmaak',
        'bold': 'Vet',
        'italic': 'Cursief',
        'deleted': 'Verwijderd',
        'link': 'Koppeling',
        'list': 'Lijst',
        'image': 'Afbeelding',
        'indent': 'Inspringen',
        'outdent': 'Uitspringen',
        'embed': 'Insluiten',
        'table': 'Tabel',
        'insert': 'Toevoegen',
        'save': 'Opslaan',
        'cancel': 'Annuleren',
        'delete': 'Verwijderen',
        'duplicate': 'Dupliceren',
        'shortcut': 'Snelkoppelingen',
        'underline': 'Onderstrepen',
        'undo': 'Ongedaan maken',
        'redo': 'Opnieuw',
        'code': 'Code',
        'mark': 'Markeren',
        'subscript': 'Subscript',
        'superscript': 'Superscript',
        'kbd': 'Sneltoets',
        'image-settings': 'Afbeeldinginstellingen',
        'column-settings': 'Kolominstellingen',
    },
    blocks: {
        text: 'Tekst',
        paragraph: 'Paragraaf',
        image: 'Afbeelding',
        embed: 'Insluiting',
        line: 'Lijn',
        table: 'Tabel',
        quote: 'Citaat',
        pre: 'Code',
        address: 'Adres',
    },
    format: {
        p: 'Normale Tekst',
        h1: 'Koptekst 1',
        h2: 'Koptekst 2',
        h3: 'Koptekst 3',
        h4: 'Koptekst 4',
        h5: 'Koptekst 5',
        h6: 'Koptekst 6',
        address: 'Adres',
        ul: 'Ongeordende lijst',
        ol: 'Geordende lijst',
        dl: 'Definitielijst',
    },
    embed: {
        'embed': 'Insluiten',
        'caption': 'Titel',
        'description': 'Plak HTML-code of voer een URL in (alleen Vimeo- of Youtube-videos)',
        'responsive-video': 'Responsieve video',
    },
    image: {
        'or': 'of',
        'alt-text': 'Alt-tekst',
        'link': 'Koppeling',
        'width': 'Breedte',
        'caption': 'Titel',
        'link-in-new-tab': 'Open koppeling in een nieuwe tab',
        'url-placeholder': 'Plak URL van afbeelding…',
        'upload-new-placeholder': 'Sleep een afbeelding om het te uploaden<br>of klik om er één te kiezen',
    },
    link: {
        'link': 'Koppeling',
        'edit-link': 'Bewerk Koppeling',
        'unlink': 'Ontkoppelen',
        'link-in-new-tab': 'Open koppeling in een nieuwe tab',
        'text': 'Tekst',
        'url': 'URL',
    },
    table: {
        'width': 'Breedte',
        'nowrap': 'Niet omlopen',
        'column': 'Kolom',
        'add-head': 'Voeg kop toe',
        'remove-head': 'Verwijder kop',
        'add-row-below': 'Voeg kop hieronder toe',
        'add-row-above': 'Voeg kop hierboven toe',
        'remove-row': 'Verwijder rij',
        'add-column-after': 'Voeg kolom hierna toe',
        'add-column-before': 'Voeg kolom hiervoor toe',
        'remove-column': 'Verwijder kolom',
        'delete-table': 'Verwijder tabel',
    },
    removeformat: {
        removeformat: 'Verwijder Opmaak',
    },
    alignment: {
        alignment: 'Uitlijning',
    },
    blockcode: {
        'save': 'Opslaan',
        'cancel': 'Annuleren',
        'edit-code': 'Bewerk Code',
    },
    clips: {
        clips: 'Knipsels',
    },
    counter: {
        words: 'woorden',
        chars: 'karakters',
    },
    filelink: {
        file: 'Bestand',
        upload: 'Uploaden',
        title: 'Titel',
        choose: 'Kies',
        placeholder: 'Sleep een bestand om het te uploaden<br>of klik om er één te kiezen',
    },
    handle: {
        handle: 'Vermelding',
    },
    icons: {
        icons: 'Iconen',
    },
    imageposition: {
        'image-position': 'Afbeeldingpositie',
    },
    imageresize: {
        'image-resize': 'Afbeeldinggrootte',
    },
    inlineformat: {
        'inline-format': 'In-regel opmaak',
        'underline': 'Onderstrepen',
        'superscript': 'Superscript',
        'subscript': 'Subscript',
        'mark': 'Markeren',
        'code': 'Code',
        'shortcut': 'Koppeling',
        'remove-format': 'Verwijder Opmaak',
    },
    specialchars: {
        'special-chars': 'Speciale Karakters',
    },
    textdirection: {
        title: 'RNL-LNR',
        ltr: 'Links naar rechts',
        rtl: 'Rechts naar links',
    },
    selector: {
        'class-id': 'HTML-Class en -ID',
    },
};
