import { UtilService } from '../../services/util.service';
import { popupModel } from './popup.model';
import { Component, forwardRef, HostListener, Input, OnInit, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValidationService } from '../../services/validation.service';
import { ConfigService } from '../../services/config.service';

@Component({
    selector: 'sInput',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements ControlValueAccessor, OnInit {
    @Input() id: string;
    @Input() autocomplete: boolean;
    @Input() label: string;
    @Input() explainer: string;
    @Input() type: string = 'text';
    @Input() name: string;
    @Input() placeholder: string = '';
    @Input() disabled = false;
    @Input() readonly = false;
    @Input() required;
    @Input() sClass: string = '';
    @Input() noMargin: boolean;
    @Input() strengthCheck: boolean = false;
    @Input() showPasswordIcon: boolean = false;
    @Input() error: boolean = false;
    @Input() maxLength: number;
    @Input() _functionNameKeyUp: string;
    @Input() strength: string;
    @Input() icon: string = '';
    @Input() iconReverse: boolean = false;
    @Input() labelBold: boolean = false;
    @Input() locked: boolean = null;

    // holds the popup values for the input
    // create popupModel and pass to sInput
    @Input('popup') public popup: popupModel = null;

    // The strength of the password value
    public passwordStrength: string = '';
    public valueLength: number = 0;
    public onChange: (val: any) => void = () => {};
    public onTouched: () => void = () => {};

    private _value: string = '';
    get value(): string {
        return this._value;
    }
    @Input() set value(val: string) {
        this._value = val;
    }

    public constructor(
        public config: ConfigService,
        private viewContainerRef: ViewContainerRef,
        private validationService: ValidationService,
        private util: UtilService
    ) {}

    ngOnInit(): void {
        if (this.locked !== null && this.locked) {
            this.disabled = true;
        } else if (this.locked !== null && !this.locked) {
            this.disabled = false;
        }
    }

    /**
     * Updates the value of the input
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public update(event: KeyboardEvent): void {
        this.value = (<HTMLInputElement>event.target).value;
        this.valueLength = this.value.length;

        this.checkStrength();
    }

    /**
     * Registers the onChange function
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public registerOnChange(fn: (val: any) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers the onTouch function
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * Writes the value
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    public writeValue(value: string): void {
        this.value = value;
    }

    /**
     * Update the input on keyup
     *
     * @author    Mark de Mol <mark@safira.nl>
     */
    @HostListener('keyup', ['$event'])
    public keyup(event: KeyboardEvent): void {
        this.update(event);

        if (typeof this._functionNameKeyUp !== 'undefined') {
            this.getParentComponent()[this._functionNameKeyUp](event);
        }
    }

    getParentComponent(): any {
        return this.viewContainerRef['_data'].componentView.component.viewContainerRef['_view'].component;
    }

    /**
     * checks the strength of the password and updates the indicator
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    checkStrength(): void {
        if (this.type !== 'password' || !this.strengthCheck) {
            return;
        }

        this.passwordStrength = this.validationService.checkPasswordStrength(this.value);
    }

    /**
     * Checks if type is possible in the browser, returns type if possible otherwise the type is text
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    checkIfTypeIsPossibleForBrowser(type: string): string {
        const isIE: boolean = /msie\s|trident\//i.test(window.navigator.userAgent);

        if (type === 'date' && isIE) {
            return 'text';
        } else if (type === 'time' && isIE) {
            return 'text';
        } else {
            return type;
        }
    }

    /**
     * Returning the right regex for IE
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     *            returns          mixed
     */
    checkForPattern(type: string) {
        const isIE: boolean = /msie\s|trident\//i.test(window.navigator.userAgent);

        if (type === 'date' && isIE) {
            return /^(19|20)\d\d[- \/.](0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])$/i;
        } else if (type == 'date') {
            return '\\d{2}/\\d{2}/\\d{4}';
        } else if (type === 'time' && isIE) {
            return /^([01]?\d|2[0-3]):([0-5]\d)$/i;
        } else {
            return null;
        }
    }

    /**
     * Returning the right regex for IE
     *
     * @author    Wilfred van Eck <wilfred@safira.nl>
     */
    getPlaceholder(type: string): string {
        const isIE: boolean = /msie\s|trident\//i.test(window.navigator.userAgent);

        if (type === 'date' && isIE) {
            return 'dd/mm/yyyy';
        } else if (type === 'time' && isIE) {
            return '00:00';
        } else if (type === 'tel') {
            return '';
        } else if (type === 'email') {
            return 'mail@website.com';
        } else if (type === 'password') {
            return '********';
        } else {
            return '';
        }
    }

    /**
     * returns if the set type has an icon in the input
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      type    string
     * @returns    boolean
     */
    public hasIcon(type: string): boolean {
        return (
            !!this.config.typeIcons[type] ||
            this.showPasswordIcon ||
            (this.icon !== undefined && this.icon.length > 0)
        );
    }

    /**
     * open or close input popup
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public showPopup(id: string): void {
        if (id && id.length > 0) {
            this.util.showPopup(id);
        }
    }

    /**
     * disable or enable the input and show correct lock icon
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public switchInputState(): void {
        this.disabled = !this.locked;
        this.locked = !this.locked;
    }

    /**
     * Handles input
     *
     * @author     Lars Meeuwsen <lars@safira.nl>
     * @param      input    any
     * @returns    void
     */
    public handleInput(input: any): void {
        if (this.type === 'number') {
            if (!/^\d+$/.test(input.key)) {
                input.preventDefault();
            }
        }
    }
}
