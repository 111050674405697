import { Component, OnInit, ViewChild } from '@angular/core';
import { FormComponent } from '../../../../core/components/form/form.component';
import { BreadcrumbService } from '../../../../core/services/breadcrumb-service.service';
import { UserService } from '../../../../core/services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../../../core/services/toaster.service';
import { UserModel } from '../../../../core/models/user/user.model';
import { ReplaySubject } from 'rxjs';
import { UserGroupService } from '../../../../core/services/user/user-group.service';
import { AccountService } from '../../../../core/services/user/account.service';
import { AuthenticateService } from '../../../../core/services/auth/authenticate.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'detail',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.less'],
})
export class UserFormComponent implements OnInit {
    public creating: boolean;
    public isAccount: boolean;
    public canEditRights: boolean = false;
    public userId: number;
    public isSubAccount: boolean = false;
    public canLoad: boolean = false;
    private destroyed$: ReplaySubject<void> = new ReplaySubject(1);

    @ViewChild('form', { static: true }) private form: FormComponent;

    constructor(
        public account: AccountService,
        public userService: UserService,
        private userGroupService: UserGroupService,
        private authenticate: AuthenticateService,
        private activatedRoute: ActivatedRoute,
        private breadCrumb: BreadcrumbService,
        private toaster: ToasterService,
        private router: Router
    ) {}

    async ngOnInit() {
        this.isAccount = this.router.url.includes('/account/');
        this.breadCrumb.popPage();
        this.breadCrumb.pushPage('itemUser.title', '/admin/users');
        if (this.isAccount) {
            this.creating = false;
            if (!this.account.object) {
                await this.account.get();
            }
            await this.account.includeAddresses();
            this.userService.object = this.account.object;
        } else {
            this.isSubAccount = this.router.url.includes('/sub-accounts/');
            this.userId = +this.activatedRoute.snapshot.paramMap.get('userId');

            this.creating = this.router.url.slice(this.router.url.lastIndexOf('/') + 1) === 'new';
            if (!this.creating && (Number.isNaN(this.userId) || this.userId <= 0)) {
                return await this.router.navigate(['/admin/users']);
            }

            if (this.creating) {
                if (!Number.isNaN(this.userId) && this.userId > 0) {
                    await this.userService.get(this.userId);
                    this.handleBasicBreadCrumb();
                }

                this.breadCrumb.pushPage('global_new');
                this.userService.object = new UserModel(this.userService.save.bind(this.userService));
                this.userService.object.userGroups = await this.userGroupService.getAll();
                this.canEditRights = true;
                this.canLoad = true;
                return;
            }

            try {
                await this.userService.get(this.userId);
                this.handleBasicBreadCrumb();
                if (this.isSubAccount) {
                    this.userId = +this.activatedRoute.snapshot.paramMap.get('subId');
                    await this.userService.get(this.userId);
                    if (
                        this.breadCrumb.pages.findIndex(
                            (bc) => bc.text == this.userService.object.fullName
                        ) === -1
                    ) {
                        this.breadCrumb.pushPage(
                            this.userService.object.fullName,
                            `/admin/users/${this.userService.object.userId}`
                        );
                    }
                }

                await this.userService.includeUserGroups();
                await this.userService.includeAddresses();
            } catch (error) {
                this.toaster.error(error.message);
                return await this.router.navigate(['/admin/users']);
            }
        }
        this.canEditRights = this.userService.object.userId != this.account.object.userId;
        this.canLoad = true;
    }

    public handleBasicBreadCrumb() {
        if (this.breadCrumb.pages.findIndex((bc) => bc.text == this.userService.object.fullName) === -1) {
            this.breadCrumb.pushPage(
                this.userService.object.fullName,
                `/admin/users/${this.userService.object.userId}`
            );
        }
        if (
            this.breadCrumb.pages.findIndex((bc) => bc.text == 'Subaccounts') === -1 &&
            this.router.url.includes('/sub-accounts')
        ) {
            this.breadCrumb.pushPage(
                'Subaccounts',
                `/admin/users/${this.userService.object.userId}/sub-accounts`
            );
        } else if (
            this.breadCrumb.pages.findIndex((bc) => bc.text == 'Subaccounts') >= 0 &&
            !this.router.url.includes('/sub-accounts')
        ) {
            this.breadCrumb.popPage();
        }
    }

    async ngAfterContentInit() {
        this.form.formSubmitted.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
            try {
                if (this.router.url.match(/^\/admin\/users\/[0-9]{1,6}\/sub-accounts\/new$/)) {
                    this.userService.object.parentId = +this.activatedRoute.snapshot.paramMap.get('userId');
                }
                // Saves the object so could be the account
                await this.userService.object.save();

                // If account only get the needed addresses
                if (this.isAccount) {
                    await this.account.includeAddresses();
                    this.userService.object = this.account.object;
                } else {
                    await this.userService.includeUserGroups();
                    await this.userService.includeAddresses();
                }
            } catch (error) {
                return this.toaster.error(error.message);
            }
            if (this.creating) {
                this.toaster.success('itemUser.success_created');

                await this.authenticate.sendPasswordResetEmail(this.userService.object.email);
                if (this.router.url.includes('/sub-accounts')) {
                    await this.router.navigate([
                        `/admin/users/${this.userService.object.parentId}/sub-accounts`,
                    ]);
                } else {
                    await this.router.navigate(['/admin/users']);
                }
            }

            this.toaster.success('itemUser.success_updated');
        });
    }

    openSubAccounts() {
        if (!this.creating && !(Number.isNaN(this.userId) || this.userId <= 0)) {
            this.breadCrumb.pushPage(
                'Subaccounts',
                `/admin/users/${this.userService.object.userId}/sub-accounts`
            );
            this.router.navigate([`/admin/users/${this.userId}/sub-accounts`]);
        }
    }

    ngOnDestroy() {
        this.breadCrumb.popPage();
    }
}
