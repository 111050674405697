import { ToasterService } from '../../../../../core/services/toaster.service';
import { Component, OnInit } from '@angular/core';
import { UploadService } from '../../../../../core/services/upload.service';

@Component({
    selector: 'excel-read',
    templateUrl: './excel-read.component.html',
    styleUrls: ['./excel-read.component.less'],
})
export class ExcelReadComponent implements OnInit {
    public excelContent: any = [];

    constructor(private uploadService: UploadService, private toaster: ToasterService) {}

    ngOnInit(): void {}

    /**
     * Upload file
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public async uploadFile(fileList) {
        let files = this.checkAllowedExtensions(fileList);
        if (files.length > 0) {
            let responseData = await this.uploadService.uploadFiles(files, 'excel/readExcel');
            responseData = responseData[0];

            if (responseData['error']['code'] === 0) {
                this.excelContent = responseData['data'];
            }
        }
    }

    /**
     * checkAllowedExtensions
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @param      fileList:    FileList
     * @returns    void
     */
    private checkAllowedExtensions(fileList: FileList): File[] {
        let allowedFiles: any[] = [];
        for (let i = 0; i < fileList.length; i++) {
            if (fileList.item(i).name.endsWith('.xlsx')) {
                allowedFiles[i] = fileList.item(i);
            } else {
                this.toaster.error(fileList.item(i).name + ': bestandstype niet ondersteund.');
            }
        }
        return allowedFiles;
    }
}
