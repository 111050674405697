import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormComponent } from './form.component';
import { InputComponent } from '../input/input.component';
import { SelectComponent } from '../select/select.component';
import { TextareaComponent } from '../textarea/textarea.component';
import { DashboardBoxComponent } from '../dashboard-box/dashboard-box.component';
import { InputDirective } from '../input/input.directive';
import { ButtonComponent } from '../button/button.component';
import { SignatureComponent } from '../signature/signature.component';
import { SearchboxComponent } from '../searchbox/searchbox.component';
import { CardComponent } from '../card/card.component';
import { SelectorComponent } from '../selector/selector.component';
import { QuantityComponent } from '../quantity/quantity.component';
import { FileuploadComponent } from '../fileupload/fileupload.component';
import { CropperComponent } from '../cropper/cropper.component';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { TranslationModule } from '../../../modules/translate.module';
import { SelectDirective } from '../select/select.directive';
import { RadioComponent } from '../radio/radio.component';
import { ToggleComponent } from '../toggle/toggle.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { CarouselComponent } from '../carousel/carousel.component';
import { PopupModule } from '../popup/popup.module';

@NgModule({
    declarations: [
        FormComponent,
        InputComponent,
        InputDirective,
        SelectComponent,
        SelectDirective,
        TextareaComponent,
        RadioComponent,
        ToggleComponent,
        CheckboxComponent,
        ButtonComponent,
        SignatureComponent,
        SearchboxComponent,
        CardComponent,
        SelectorComponent,
        QuantityComponent,
        FileuploadComponent,
        DashboardBoxComponent,
        CropperComponent,
        CarouselComponent,
    ],
    imports: [CommonModule, RouterModule, TranslationModule, PopupModule],
    exports: [
        FormComponent,
        InputComponent,
        SelectComponent,
        TextareaComponent,
        RadioComponent,
        ToggleComponent,
        CheckboxComponent,
        ButtonComponent,
        SignatureComponent,
        SearchboxComponent,
        CardComponent,
        SelectorComponent,
        QuantityComponent,
        FileuploadComponent,
        DashboardBoxComponent,
        CropperComponent,
        CarouselComponent,
    ],
    providers: [
        FormComponent,
        InputComponent,
        SelectComponent,
        TextareaComponent,
        RadioComponent,
        ToggleComponent,
        CheckboxComponent,
        ButtonComponent,
        SelectorComponent,
        FileuploadComponent,
        DashboardBoxComponent,
        TranslatePipe,
    ],
})
export class FormModule {}
