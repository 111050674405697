import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'info-popup',
    templateUrl: './info-popup.component.html',
    styleUrls: ['./info-popup.component.less'],
})
export class InfoPopupComponent implements OnInit {
    @Output() popupClosed: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}
}
