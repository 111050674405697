import { ConfigService } from '../../services/config.service';
import { Component, ElementRef, forwardRef, Input, QueryList, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchboxOption } from './searchbox.model';
import { UtilService } from '../../services/util.service';
import { LanguageService } from '../../services/language.service';
import { TranslateService } from '../../services/translate.service';

@Component({
    selector: 'sSearchbox',
    templateUrl: './searchbox.component.html',
    styleUrls: ['./searchbox.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchboxComponent),
            multi: true,
        },
    ],
})
export class SearchboxComponent implements ControlValueAccessor {
    // The available options
    @Input('options') public options: Array<SearchboxOption> = [];
    public filteredOptions: Array<SearchboxOption> = [];
    // toon woorden bij nauwkeurigheid van 70%
    public MATCH_ACCURACY: number = 70;
    public none: boolean = false;
    // Event handlers from Angular
    public onChange: (val: any) => void;
    public onTouched: () => void;
    @ViewChildren('option') private optionElements: QueryList<ElementRef>;

    public constructor(
        private translate: TranslateService,
        private language: LanguageService,
        private config: ConfigService,
        private util: UtilService
    ) {}

    // Value of the currently selected option
    @Input('value') private _value: string = '';

    public get value(): string {
        return this._value;
    }

    public set value(val: string) {
        this._value = val;

        try {
            // Scroll the option list to make the option fully visible
            if (this.optionElements == null) {
                return;
            }
            const selectedOption: Element = this.optionElements.find(
                ({ nativeElement: ele }: ElementRef) => ele.dataset.value === val
            ).nativeElement;
            const list: Element = selectedOption.parentElement;
            const optionRect: DOMRect = selectedOption.getBoundingClientRect();
            const listRect: DOMRect = list.getBoundingClientRect();

            if (listRect.top > optionRect.top) {
                list.scrollTop -= listRect.top - optionRect.top;
            } else if (optionRect.bottom > listRect.bottom) {
                list.scrollTop += optionRect.bottom - listRect.bottom;
            }
        } catch (e) {}
    }

    /**
     * Writes a new value to the element.
     *
     * For model changes
     */
    public writeValue(value: string): void {
        this.value = value;
    }

    /**
     * Registers the onChange callback
     */
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * Registers the onTouched callback
     */
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * When the user chooses an option
     */
    public onOptionSelected(value: string): void {
        this.value = value;

        // Let Angular know about the change
        this.onChange(value);
        this.onTouched();
    }

    public change(event: Event) {
        this.value = (event.target as HTMLInputElement).value;
        this.onChange(this.value);
    }

    /**
     * Filter options based on search box value
     */
    public filterOptions(search: string): void {
        const lang = this.language.current.iso;
        const searchName = search.toLocaleLowerCase(lang);

        this.value = search;
        this.onChange(this.value);

        if (search === '') {
            this.filteredOptions = [];
            return;
        }

        this.filteredOptions = this.filter();
    }

    public filter(): SearchboxOption[] {
        let filterArray: SearchboxOption[] = [];
        this.options.forEach((option: SearchboxOption) => {
            if (this.util.getMatchAccuracy(this.value, option.name) >= this.MATCH_ACCURACY) {
                filterArray.push(option);
            }
        });
        return filterArray;
    }
}
