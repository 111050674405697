import { Directive, HostListener } from '@angular/core';
import { ValidationService } from 'src/app/core/services/validation.service';
import { UtilService } from 'src/app/core/services/util.service';

@Directive({
    selector: '[ibanValidation]',
})
export class IbanValidationDirective {
    @HostListener('change', ['$event'])
    public onChange(e: Event): void {
        const $target: HTMLInputElement = <HTMLInputElement>e.target;
        const $sInput: HTMLElement = this.util.getParentByTagName($target, 'sInput');
        const $spinner: HTMLElement = this.util.findChildrenWithClass($sInput, 'fa-spin')[0];
        const value: string = $target.value;

        // temporary disable the field and show the spinner
        $target.disabled = true;
        this.util.removeClass($spinner, 'hide');

        this.ibanValidation(value).then((result) => {
            // get the sInput component, this is the place the validation error should be applied on
            if (result['valid']) {
                this.util.removeClass($sInput, 'validation-error');
                this.validation.errors.ibanValidation = true;
            } else {
                this.util.addClass($sInput, 'validation-error');
                this.validation.errors.ibanValidation = false;
            }

            // enable the field again and hide spinner
            $target.disabled = false;
            this.util.addClass($spinner, 'hide');
        });
    }

    public constructor(private validation: ValidationService, private util: UtilService) {}

    /**
     * ibanValidation
     *
     * @author     Eric van Doorn <Eric@safira.nl>
     * @param      value    string
     * @returns    Promise<any>
     */
    private ibanValidation(value: string): Promise<any> {
        return this.validation.getIbanValidation(value);
    }
}
