import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

export interface MenuItem {
    text: string;
    active: boolean;
    icon?: string;
    click: () => void;
}

@Injectable({
    providedIn: 'root',
})
export class MobileMenuService {
    private menuItems: MenuItem[] = [];
    public menuChanged = new Subject<number>();
    public active: boolean = false;

    constructor() {}

    handleClick(item: MenuItem): void {
        this.menuItems.forEach((menItem) => {
            menItem.active = menItem == item;
        });
    }

    addButtons(menuItems: MenuItem[]): void {
        this.menuItems.push(...menuItems);
        this.menuChanged.next(this.menuItems.length);
    }

    getMenuItems(): MenuItem[] {
        return this.menuItems.slice();
    }

    clearMenuItems(): void {
        this.menuItems = [];
        this.menuChanged.next(0);
    }

    removeMenuItem(menuItem: MenuItem): void {
        let index = this.menuItems.findIndex((item) => item === menuItem);
        if (index >= 0) {
            this.menuItems.splice(index, 1);
            this.menuChanged.next(this.menuItems.length);
        }
    }
}
