import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticateService } from '../../../core/services/auth/authenticate.service';
import { AuthorisationService } from '../../../core/services/auth/authorisation.service';
import { TranslateService } from '../../../core/services/translate.service';

@Component({
    selector: 'set-password',
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.less'],
})
export class SetPasswordComponent implements OnInit, OnDestroy {
    public newPassword: string = '';
    public newPasswordRepeat: string = '';
    public error: boolean = false;
    public sendPassword: boolean = false;
    public validHash: boolean = null;
    public hash: string = null;
    public passwordErrorId: string = 'password-error';
    public canSubmit: boolean = false;

    public paramSubscription: Subscription = null;

    public constructor(
        public authenticate: AuthenticateService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private auth: AuthorisationService,
        private router: Router
    ) {
        if (this.auth.canAccess()) {
            this.router.navigate(['/dashboard']);
        }
    }

    public ngOnInit(): void {
        this.paramSubscription = this.route.params.subscribe((params: Params) => {
            this.hash = params['hash'];

            this.authenticate.checkResetPasswordHashAndTime(this.hash).then((result: boolean) => {
                this.validHash = result;
            });
        });
    }

    public ngOnDestroy(): void {
        this.paramSubscription?.unsubscribe();
    }

    /**
     * Gets a translation
     *
     * @param    label    The    language label in itemModule.label format
     */
    public translate(label: string): string {
        return this.translateService.getParsedResult(label, []);
    }
}
