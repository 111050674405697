/// <reference types="@types/googlemaps" />
import { OnDestroy } from '@angular/core';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Coordinates } from '../../services/location/abstractLocation';
import { MapMarker } from 'src/app/core/services/location/MapMarker.model';
import { LocationService } from '../../services/location/location.service';
@Component({
    selector: 'googleMaps',
    templateUrl: './googlemaps.component.html',
    styleUrls: ['./googlemaps.component.less'],
})
export class GoogleMapsComponent implements OnInit, OnDestroy {
    /* Height of the map */
    @Input('mapHeight') public mapHeight: number;
    /* Array of markers */
    @Input('markers') public markers: Array<MapMarker>;
    @Input('latitude') public latitude: number;
    @Input('longitude') public longitude: number;
    /* How far or near the map is zoomed */
    @Input('zoomLevel') public zoomLevel: string;
    /* Type of map shown, options below in stringToMapType */
    @Input('mapType') public mapType: string;
    /* Whether the map is draggable */
    @Input('draggable') public draggable: boolean = false;
    @ViewChild('gmap', { static: true }) private gmapElement: ElementRef;
    private map: google.maps.Map;
    private gMarkers: google.maps.Marker[] = [];
    /* Show zoom controls (visually) */
    @Input('zoomControl') private zoomControl: boolean = false;
    /* Whether the icons are clickable */
    @Input('clickableIcons') private clickableIcons: boolean = false;
    /* Whether the map type can be changed on the map */
    @Input('mapTypeControl') private mapTypeControl: boolean = false;
    /* Whether the map can be toggled fullscreen  */
    @Input('fullscreenControl') private fullscreenControl: boolean = false;
    /* Whether the keyboard can be used or not */
    @Input('keyboardShortcuts') private keyboardShortcuts: boolean = false;
    /* Whether street view can be enabled or not */
    @Input('streetViewControl') private streetViewControl: boolean = false;
    private iconUrl: string = 'assets/custompointer.png';
    /* Possible options for map type */
    private stringToMapType: { [typeStr: string]: google.maps.MapTypeId } = {
        roadmap: google.maps.MapTypeId.ROADMAP,
        terrain: google.maps.MapTypeId.TERRAIN,
        satellite: google.maps.MapTypeId.SATELLITE,
        hybrid: google.maps.MapTypeId.HYBRID,
    };

    private infoWindows: google.maps.InfoWindow[] = [];

    public constructor(public locationService: LocationService) {}

    public ngOnInit(): void {
        this.loadMap();
    }
    private loadMap() {
        /* Options for the map */
        let mapOptions: google.maps.MapOptions = {
            center: new google.maps.LatLng(this.latitude, this.longitude),
            zoom: parseInt(this.zoomLevel) > 18 ? 18 : parseInt(this.zoomLevel),
            mapTypeId: this.stringToMapType[this.mapType],
            draggable: this.draggable,
            clickableIcons: this.clickableIcons,
            fullscreenControl: this.fullscreenControl,
            keyboardShortcuts: this.keyboardShortcuts,
            mapTypeControl: this.mapTypeControl,
            streetViewControl: this.streetViewControl,
            zoomControl: this.zoomControl,
        };
        /* Creating a new map */
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);
        /* Looping throught the given map markers */
        this.addMarkers(this.markers);
    }
    /**
     *
     * Remove marker from map based on longitude and latitude
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public removeMarker(marker: MapMarker) {
        // remove marker from map
        let gMarkerIndex = this.gMarkers.findIndex(
            (mark) =>
                mark.getPosition().lat() == marker.latitude && mark.getPosition().lng() == marker.longitude
        );
        if (gMarkerIndex >= 0) {
            this.gMarkers[gMarkerIndex].setMap(null);
            this.gMarkers[gMarkerIndex] = null;
            this.gMarkers.splice(gMarkerIndex, 1);
        }
        // remove marker from array
        let index = this.markers.findIndex(
            (mark) => mark.longitude == marker.longitude && mark.latitude == mark.latitude
        );
        if (index >= 0) {
            this.markers.splice(index, 1);
        }
    }
    /**
     *
     * Set center of map
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    public setPosition(coordinates: Coordinates): void {
        this.map.setCenter(new google.maps.LatLng(coordinates.lat, coordinates.lon));
    }
    /**
     * getContentString
     *
     * Creating a content string for the info window
     *
     * @author        Wilfred van Eck <wilfred@safira.nl>
     * @lastEdit      12-09-2018 09:41
     * @lastEditBy    Wilfred van Eck <wilfred@safira.nl>
     */
    public addMarkers(markers: MapMarker[]) {
        markers.forEach((marker: MapMarker) => {
            /* Creating each marker */
            let mapsMarker: google.maps.Marker = new google.maps.Marker({
                position: new google.maps.LatLng(marker.latitude, marker.longitude),
                map: this.map,
                icon: {
                    url: !marker.iconUrl || marker.iconUrl == '' ? this.iconUrl : marker.iconUrl,
                    scaledSize: new google.maps.Size(24, 32),
                },
                title: marker.title,
            });
            /* Creating an info window for each marker */
            var infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();
            /* Adding a listener so that each map marker opens an info window */
            mapsMarker.addListener('click', () => {
                this.closeOpenInfoWindows();
                this.locationService.activeMarker = JSON.parse(JSON.stringify(marker));
                setTimeout(() => {
                    infoWindow.setContent(document.getElementById('maps-window').innerHTML);
                    this.infoWindows.push(infoWindow);
                    infoWindow.open(this.map, mapsMarker);
                }, 1);
            });
            this.gMarkers.push(mapsMarker);
        });
    }

    /**
     *
     * close all open google info windows by simulating a close click.
     *
     * @author     Ruben Janssens <ruben@safira.nl>
     * @returns    void
     */
    private closeOpenInfoWindows() {
        this.infoWindows.forEach((window: google.maps.InfoWindow) => {
            window.close();
        });
    }

    /**
     * setMapType
     *
     * Changes the type of the map
     *
     * Example: (click)="setMapType('satellite')" on a button changes the type of the map to Satellite
     *
     * @author        Wilfred van Eck <wilfred@safira.nl>
     * @lastEdit      12-09-2018 09:41
     * @lastEditBy    Wilfred van Eck <wilfred@safira.nl>
     */
    public setMapType(mapTypeId: string): void {
        this.map.setMapTypeId(mapTypeId);
    }

    ngOnDestroy(): void {
        this.locationService.activeMarker = null;
    }
}
