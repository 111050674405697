import { Directive, OnInit, NgZone, ElementRef } from '@angular/core';
import RedactorX from '../../../../dist/redactorx/redactorx.usm.min.js';
import '../../../../dist/redactorx-safira/langs/nl.js';
import '../../../../dist/redactorx/plugins/alignment/alignment.js';
import '../../../../dist/redactorx/plugins/clips/clips.js';

@Directive({
    selector: '[Redactor]',
})
export class RedactorDirective implements OnInit {
    public constructor(private zone: NgZone, private el: ElementRef) {}

    public ngOnInit(): void {
        this.zone.runOutsideAngular(() => {
            RedactorX(this.el.nativeElement, {
                editor: {
                    lang: 'nl',
                    minHeight: '300px',
                    maxHeight: '600px',
                },
                plugins: ['alignment', 'clips'],
                clips: {
                    items: {
                        lipsum: {
                            title: '<i class="fal fa-font" aria-hidden="true"></i> Lorem ipsum voorbeeld tekst',
                            html: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                        },
                        button: {
                            title: '<i class="fal fa-link" aria-hidden="true"></i> Link knop',
                            html: '<a rel="button" href="#">Knop tekst</a>',
                        },
                    },
                },
                format: ['p', 'h1', 'h2', 'h3', 'h4'],
            });
        });
    }
}
