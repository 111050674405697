import { UtilService } from 'src/app/core/services/util.service';
import { Component, OnInit } from '@angular/core';
import { SearchboxOption } from 'src/app/core/components/searchbox/searchbox.model';
import { AjaxService } from 'src/app/core/services/ajax.service';
import { ToasterService } from '../../../core/services/toaster.service';

@Component({
    selector: 'two-factor-authentiction',
    templateUrl: './two-factor-authentication.component.html',
    styleUrls: ['./two-factor-authentication.component.less'],
})
export class TwoFactorAuthenticationComponent implements OnInit {
    public settings: {
        title: string;
        name: string;
        model: string;
        config: string;
        options: { label: string; value: string | boolean; disabled?: any }[];
    }[] = [
        {
            title: 'itemSettings.auth_method',
            name: 'authmethod',
            model: undefined,
            config: 'method',
            options: [
                {
                    label: 'itemSettings.disabled',
                    value: '0',
                },
                {
                    label: 'itemSettings.email',
                    value: '2',
                },
                {
                    label: 'itemSettings.google',
                    value: '1',
                },
            ],
        },
        {
            title: 'itemSettings.auth_expiration',
            name: 'authfrequency',
            model: undefined,
            config: 'frequency',
            options: [
                {
                    label: 'itemSettings.once',
                    value: null,
                },
                {
                    label: 'itemSettings.week_expiration',
                    value: '604800',
                },
                {
                    label: 'itemSettings.month_expiration',
                    value: '2419200',
                },
            ],
        },
        {
            title: 'itemSettings.email_expiration',
            name: 'authexpirationcode',
            model: undefined,
            config: 'expirationCode',
            options: [
                {
                    label: 'itemSettings.never',
                    value: null,
                },
                {
                    label: 'itemSettings.10_min_expiration',
                    value: '600',
                },
                {
                    label: 'itemSettings.30_min_expiration',
                    value: '1800',
                },
                {
                    label: 'itemSettings.1_hour_expiration',
                    value: '3600',
                },
            ],
        },
    ];

    public searchText: string = '';
    public data: SearchboxOption[] = [
        { value: '0', name: 'Twee factor authenticatie methode' },
        { value: '1', name: 'Twee factor authenticatie verlooptijd' },
        { value: '2', name: 'Twee factor emailcode verlooptijd' },
    ];

    constructor(public ajax: AjaxService, private toaster: ToasterService, public util: UtilService) {}

    ngOnInit() {
        throw new Error("test");
        this.getConfig();
    }

    /**
     * Get config
     *
     * @author    Jeromy Hettinga <jeromy@safira.nl>
     * @param     none
     * @return    void
     */
    public async getConfig(): Promise<void> {
        const res = await this.ajax.get('systemConfig/getConfig');
        this.settings[0].model = res['blocks_authentication_method'];
        this.settings[1].model = res['blocks_authentication_frequency'];
        this.settings[2].model = res['blocks_authentication_expirationcode'];
        this.checkDisabledMethod();
    }

    /**
     * @author    Jeromy Hettinga <jeromy@safira.nl>
     * @param     $key    string
     * @return    void
     */
    public async saveConfig(key: string) {
        this.checkDisabledMethod();
        const values = {
            method: this.settings[0].model,
            frequency: this.settings[1].model,
            expirationCode: this.settings[2].model,
        };
        let value = values[key];
        key = 'authentication_' + key;
        await this.ajax.post('/systemConfig/saveConfig', { key: key, value: value });
        this.toaster.success('itemSettings.succes');
    }

    /**
     * @author     Mitchell Sterrenberg <mitchell@safira.nl>
     * @param      number    string
     * @param      filter    string
     * @returns    boolean
     */
    public filter(number: number, filter: string): boolean {
        let search: string = this.searchText;
        if (search === this.data[number].value) {
            search = filter;
        }
        return this.util.getMatchAccuracy(search, filter) > 70;
    }

    /**
     * @author     Mitchell Sterrenberg <mitchell@safira.nl>
     * @returns    void
     */
    public checkDisabledMethod(): void {
        if (this.settings[0].model === '0') {
            this.settings.forEach((item) => {
                if (
                    item.title === 'itemSettings.auth_expiration' ||
                    item.title === 'itemSettings.email_expiration'
                ) {
                    item.options.forEach((option) => {
                        option.disabled = true;
                    });
                }
            });
        } else {
            this.settings.forEach((item) => {
                item.options.forEach((option) => {
                    option.disabled = false;
                });
            });
        }
    }
}
