import { AfterContentInit, Component, HostListener } from '@angular/core';
import { item } from '../../models/item.model';
import { NavigationService } from '../../services/navigation.service';
import { DropdownAction } from '../../models/action.model';
import { ResponsiveService } from '../../services/responsive.service';

@Component({
    selector: 'sNavigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.less'],
})
export class NavigationComponent implements AfterContentInit {
    open: boolean = false;

    constructor(public navigation: NavigationService, public responsive: ResponsiveService) {}

    @HostListener('window:resize')
    onResize() {
        this.navigation.resetMenu();
    }

    async ngAfterContentInit() {
        this.navigation.resetMenu();
    }

    clickItem(item: item) {
        if (!this.navigation.sideNav && window.innerWidth > this.navigation.breakpoint) {
            return;
        }
        if (item.action !== undefined && item.action.type === 'dropdown') {
            let dropdown = item.action as DropdownAction;
            this.navigation.setSubmenu(dropdown.items);
            return;
        }
        this.open = false;
    }
}
